
const hiSetting = {
    //项目名
    projectName: 'eap5csxm/admin',
    pName:"eap5csxm",
    pUser:"admin",
    //接口返回消息弹窗模式，默认为消息提示（自动消失），msgBox为消息弹窗（需手动确定关闭）
    messageType: "msgBox",
    //请求对象
    //request: request,
    //部署目录
    deployDir: "",
    //服务器域名
    url: "http://192.168.4.105:7777",
    //编辑器脚本路径
    editorResDir: "",
    //服务名称
    serverName: "",
    //默认服务url
    serverUrl: "",
    //用户信息
    userInfo: "/sys/user/detail",
    //登录页url
    loginUrl: "/index.html",
    //小登录窗口登录页url
    miniLoginUrl: "/index.html",
    //首页url
    mainPageUrl: "/main.html",
    //数据保存url
    saveUrl: "/data/save", //'/data/save',
    //数据查询url
    queryUrl: "/data/query", //'/data/query',
    //excel导出心跳
    exportExcelHeartbeatUrl: "/export/dataexcel/status",
    //导入URL
    excelImportUrl: "/import/exceldata",
    //导出URL
    exportUrl: "/export/dataexcel",
    exportPathUrl: "/export/dataexcel/path",
    exportNewUrl: "/export/dataexcelnew",
    //页面初始化接口
    pageInitUrl: "/data/page/permit",
    //数据策略url
    policyUrl: "/data/policy",
    //附件上传url
    upload: "/file/upload",
    //附件下载url
    download: "/file/download",
    //附件查看url
    review: "/file/static/preview",
    //流程信息url
    getFlowInfo: "/flow/open",
    getApprovalInfo: "/flow/getApprovalInfo",
    processFlow: "/flow/processFlow",
    umpireOrder: "/flow/umpireOrder",
    queryTask: "/flow/queryTask",
    withdrawtask: "/flow/withdrawtask",
    taskDiagram: "/flow/taskDiagram",
    openOrdernumber: "/flow/openOrdernumber",
    //默认查询操作符
    queryOperate: 'like',
    //数据返回超时时间
    requestTimeout: 140 * 1000,
    //是否单点登录
    isSingleLogin: false,
    //单点登录页
    singleLoginUrl: "",
    //单点登出页
    singleLogoutUrl: "",
}

export default hiSetting